.Goita .Game{
  display:flex;
}

.Goita .Field {
  width: 800px;
  height: 800px;
  background-color: darkgreen;
  display: inline-block;
  position: relative;
}

.Goita .Action{
  position:absolute;
  left:25%;
  bottom: 72px;
}

.Goita .ActionMessage{
  background-color: white;
}

.Goita .Player {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  border: none;
}

.Goita .Status{
  position: absolute;
  top:60%;
  left:10%;
  background-color: white;
  width: 100px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  padding:4px;
}

.Goita .PlayerField {
  position: relative;
  top: 150px;
  left: 30%;
}

.Goita .Uke {
  margin-top: 10px;
  display: flex;
}

.Goita .Seme {
  margin-top: 10px;
  display: flex;
}

.Goita .Other {
  width: 100%;
  height: 50%;
  border: none;
}

.Goita .Other1 {
  transform-origin: top left;
  transform: rotate(270deg);
  position: absolute;
  left:50%;
  top: 100%;
}


.Goita .Other2 {
  transform: rotate(180deg);
  position: absolute;
}

.Goita .Other3 {
  transform-origin: top left;
  transform: rotate(90deg);
  position: absolute;
  left:50%;
}


.Goita .Hand {
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 100px;
}

.Goita .Card {
  width: 50px;
  height: 60px;
  margin-left: 5px;
  background-color: burlywood;
  font-size:xx-large;
  display:flex;
  justify-content:center;
  align-items:center;
  outline: 1px black solid;
  outline-offset: -2px;
}

.Goita .Trick{
  outline: 4px double red;
  outline-offset: -5px;
}

.Goita .CanSelect {
  outline: 4px #000000 double;
  outline-offset: -5px;
}

.Goita .Selected {
  outline: 4px #ff0000 double;
  outline-offset: -5px;
}


.Goita .Wait{
  outline: double;
  outline-offset: -2px;
}

.Goita .Message{
  position:absolute;
  top: 32%;
  right: 32%;
  width: 36%;
  height: 36%;
  background-color: darkgray;
  font-size:large;
}

.Goita .PlayerSetting>div{
  margin-top:10px;
}

.Goita .Summary td{
  outline: 1px black solid;
}