.Backgammon .Field {
  background-color: darkgreen;
  width: 705px;
  height: 650px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: space-between;
}

.Backgammon .BoardOpponent .Field {
  transform: rotateX(180deg);
}

.Backgammon .Point {
  background-color: burlywood;
  height: 300px;
  width: 50px;
  outline: 1px black double;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Backgammon .Point:nth-child(1) {
  background-color: gray;
  margin-left: 5px;
}

.Backgammon .Point:nth-child(7) {
  margin-left: 50px;
}

.Backgammon .Point:nth-child(20) {
  margin-left: 50px;
}

.Backgammon .Point:nth-child(n + 14) {
  /* flex-direction:column-reverse; */
  transform: rotate(180deg);
}

.Backgammon .Point:nth-child(14) {
  order: 25;
}
.Backgammon .Point:nth-child(15) {
  order: 24;
}
.Backgammon .Point:nth-child(16) {
  order: 23;
}
.Backgammon .Point:nth-child(17) {
  order: 22;
}
.Backgammon .Point:nth-child(18) {
  order: 21;
}
.Backgammon .Point:nth-child(19) {
  order: 20;
}
.Backgammon .Point:nth-child(20) {
  order: 18;
}
.Backgammon .Point:nth-child(21) {
  order: 17;
}
.Backgammon .Point:nth-child(22) {
  order: 16;
}
.Backgammon .Point:nth-child(23) {
  order: 15;
}
.Backgammon .Point:nth-child(24) {
  order: 14;
}
.Backgammon .Point:nth-child(25) {
  order: 13;
}
.Backgammon .Point:nth-child(26) {
  order: 12;
  background-color: gray;
  margin-left: 5px;
}

.Backgammon .CanSelect {
  background-color: brown !important;
}

.Backgammon .Selected {
  background-color: darkslategray !important;
}

.Backgammon .CanMove {
  background-color: brown !important;
}

.Backgammon .Piece {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 0.5px black solid;
  margin: 0px;
  flex-shrink: 0;
  box-sizing: border-box;
}

.Backgammon .Piece0 {
  background-color: crimson;
}

.Backgammon .Piece1 {
  background-color: white;
}

.Backgammon .Piece:nth-child(n + 6) {
  position: relative;
  top: -220px;
}

.Backgammon .Piece:nth-child(n + 11) {
  position: relative;
  top: -435px;
}

.Backgammon .Last .Piece:last-child {
  border: 4px black double;
}

.Backgammon .Status {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: 1px black solid;
}

.Backgammon .Status .Piece {
  width: 15px;
  height: 15px;
}

.Backgammon .StatusYou {
  position: absolute;
  top: 600px;
  left: 750px;
}

.Backgammon .StatusOpponent {
  position: absolute;
  top: 200px;
  left: 750px;
}

.Backgammon .Turn {
  outline: 4px black double;
}

.Backgammon .Dice img {
  width: 30px;
  height: 30px;
  margin: 1px;
}

.Backgammon .Action {
  margin-top: 10px;
  height: 50px;
  display: flex;
}

.Backgammon .Action > * {
  margin-right: 10px;
}

.Backgammon .Double {
  font-size: x-large;
  position: absolute;
  top: 387px;
  left: 675px;
  width: 40px;
  height: 40px;
  border: solid;
  background-color: white;
  text-align: center;
}

.Backgammon .Rolled {
  position: absolute;
  top: 395px;
  left: 100px;
  display: flex;
}

.Backgammon .Moved {
  opacity: 0.4;
}

.Backgammon .Message {
  position: absolute;
  top: 400px;
  left: 750px;
  background-color: white;
  border: solid;
  align-items: center;
}
