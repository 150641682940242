.Daifugo .Top {
}

.Daifugo .Field {
  width: 800px;
  height: 200px;
  background-color: darkgreen;
  border: 1px #000000 solid;
  display: inline-block;
  position: relative;
  margin-top: 10px;
}

.Daifugo .FieldStatus{
  height: 40px;
}

.Daifugo .FieldTop {
  top: 20px;
  left: 40%;
  height: 50%;
  position: relative;
}

.Daifugo .FieldSecond{
  bottom: 0;
  right: 0;
  height: 50%;
  transform: scale(0.8,0.8);
  position: absolute;
}

.Daifugo .Others {
  display: flex;
  max-width: 900px;
  flex-wrap:wrap;
}

.Daifugo .Others > div {
  width: 120px;
  margin: 10px 10px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

.Daifugo .Action{
  min-height: 80px;
  margin-top: 10px;
}

.Daifugo .OtherTurn {
  outline: 5px double black;
}

.Daifugo .Other {
  outline: 1px #000000 solid;
}

.Daifugo .Turn {
  outline: 2px #000000 solid;
}

.Daifugo .TurnSelected {
  outline: 2px #ff0000 double;
  top: -10px;
}

.Daifugo .Option{
  margin-top: 100px;
}