.PrimeQK .Fields {
  display: flex;
}

.PrimeQK .FieldLeft {
  min-width: 400px;
}

.PrimeQK .Pre {
  margin-top: 30px;
}

.PrimeQK .Field {
  min-height: 120px;
  max-width: 730px;
  border: 1px #000000 solid;
  margin: 10px 0px;
  padding: 10px;
  position: relative;
}

.PrimeQK .LastField {
  min-width: 400px;
  margin-left: 20px;
}

.PrimeQK .Selected {
  outline: 6px double black;
}

.PrimeQK .Cards {
  display: inline-flex;
  flex-wrap:wrap;
}

.PrimeQK .Card {
  position: relative;
}

.PrimeQK .Card img {
  width: 70px;
}

.PrimeQK .JockerPower {
  position: absolute;
  top: 10%;
  left: 30%;
  font-size: xx-large;
  background-color: white;
}

.PrimeQK .Factor {
  min-width: 50px;
  min-height: 90px;
  font-size: xx-large;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PrimeQK .Ope {
  outline: 1px solid black;
}

.PrimeQK .Others {
  display: flex;
  flex-wrap: wrap;
}

.PrimeQK .Others > div {
  width: 120px;
  margin: 10px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PrimeQK .Hand{
  flex-wrap:wrap;
  max-width: 1100px;
}

.PrimeQK .Action {
  min-height: 80px;
  margin-top: 10px;
  display: flex;
}

.PrimeQK .Action > div {
  margin: 3px;
}

.PrimeQK .OtherTurn {
  outline: 5px double black;
}

.PrimeQK .Other {
  outline: 1px #000000 solid;
}

.PrimeQK .Option {
  margin-top: 100px;
}
