.Uma .Game{
  display:flex;
}

.Uma .Horses{
  display:flex;
  flex-wrap: wrap;
  width: 620px;
}

.Uma .Horse{
  margin: 7px;
  height: 140px;
  outline: 2px solid black;
}

.Uma .Selected{
  outline: 5px double black !important;
}

.Uma .Cards{
  display:flex;
}

.Uma .Players{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 700px;
}

.Uma .Player{
  outline: 1px solid black;
  margin-bottom: 20px;
  margin-right: 20px;
  width : 100px;
  padding: 2px;
}

.Uma .Wait{
  outline: 5px double black !important;
}

.Uma .Dices{
  display: flex;
}

.Uma .Dice img {
  width: 30px;
  height: 30px;
  margin: 1px;
  outline: 1px solid black;
}

.Uma .Race {
  height: 150px;
}

.Uma .Action{
  height: 70px;
}

.Uma .Win{
  background-color: rgb(255, 40, 0);
}

