.App {
}

.NormalMode {
  margin-top: 20px;
  margin-left: 20px;
}

.About div {
  margin-bottom: 60px;
}

.Game {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 60px;
}

.GameIcon {
  height: 80px;
}

.Help {
  margin-left: 20px;
}

.Help img {
  max-width: 100%;
}

.GameButtons{
  position: relative;
  left: 20px;
  display:flex;
}

/* .GameSetting {
  position: relative;
  left: 20px;
  top: 5px;
} */

.Name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ADs{
  display:flex;
}

.ADBanner{
  width: 500px;
  height: 80px;
  flex-shrink: 0;
}

.ADBanner iframe{
  border:none;
}
