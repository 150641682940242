.Mahjong .Field {
  width: 800px;
  height: 800px;
  background-color: darkgreen;
  display: inline-block;
  position: relative;
}

.Mahjong .Hai img {
  width: 36px;
  height: 48px;
  margin-left: 1px;
}

.Mahjong .Down img{
  width: 48px;
  height: 36px;
}

.Mahjong .Player .Now {
  outline: 2px #ff0000 double;
  outline-offset : -2px;
}

.Mahjong .Ba {
  width: 210px;
  height: 210px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 10px 10px;
}

.Mahjong .Ba div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Mahjong .Ba .Kyoku {
  font-size: xx-large;
}

.Mahjong .Ba img {
  width: 60px;
  height: 8px;
  margin-right: 3px;
}

.Mahjong .Wanpai {
  display: flex;
  position: absolute;
  top: 100px;
  left: 70px;
  align-items: center;
}

.Mahjong .Yama {
  position: absolute;
  top: 160px;
  left: 100px;
  background-color: white;
  font-size: x-large;
  border-radius: 10px 10px 10px 10px;
}

.Mahjong .Player {
  width: 100%;
  height: 50%;
  position: absolute;
  border: none;
}

.Mahjong .You {
  bottom: 0;
}

.Mahjong .Other1 {
  transform-origin: top left;
  transform: rotate(270deg);
  position: absolute;
  left: 50%;
  top: 100%;
}

.Mahjong .Other2 {
  transform: rotate(180deg);
  position: absolute;
}

.Mahjong .Other3 {
  transform-origin: top left;
  transform: rotate(90deg);
  position: absolute;
  left: 50%;
}

.Mahjong .Status {
  position: absolute;
  top: 15%;
  left: 38%;
  background-color: white;
  display: flex;
}

.Mahjong .Status .Zikaze {
  border: 2px black solid;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  margin-right: 5px;
}

.Mahjong .Status .Reach img {
  background: none;
  width: 100px;
  height: 12px;
  position: absolute;
  top: 53px;
  left: 20px;
}

.Mahjong .Status .Name {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Mahjong .Turn {
  font-weight: bold;
}

.Mahjong .Hand {
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 100px;
}

.Mahjong .Hand .Last {
  order: 99;
  margin-left: 20px;
  top: -10px;
}

.Mahjong .Hand .CanPlay:hover {
  position: relative;
  top: -10px;
}

.Mahjong .Hand .Reach {
  position: relative;
  top: -10px;
}

.Mahjong .Hand .Reach:hover {
  position: relative;
  top: -20px;
}

.Mahjong .Mati {
  position: absolute;
  bottom: 100px;
  left: 80px;
}

.Mahjong .Mati img {
  margin-right: 1px;
}

.Mahjong .Nakis {
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
  align-items: flex-end;
  flex-direction: row-reverse;
}

.Mahjong .Naki {
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
}

.Mahjong .Kakan1 {
  display: grid;
  align-content: end;
}

.Mahjong .Kakan1 :nth-child(1) {
  grid-row: 2/3;
  grid-column: 1;
}

.Mahjong .Kakan1 :nth-child(2) {
  grid-row: 1/3;
  grid-column: 2;
}

.Mahjong .Kakan1 :nth-child(3) {
  grid-row: 1/3;
  grid-column: 3;
}

.Mahjong .Kakan1 :nth-child(4) {
  grid-row: 1/2;
  grid-column: 1;
  position: relative;
  top: 8px;
}

.Mahjong .Kakan2 {
  display: grid;
  align-content: end;
}

.Mahjong .Kakan2 :nth-child(1) {
  grid-row: 1/3;
  grid-column: 1;
}

.Mahjong .Kakan2 :nth-child(2) {
  grid-row: 2/3;
  grid-column: 2;
}

.Mahjong .Kakan2 :nth-child(3) {
  grid-row: 1/3;
  grid-column: 3;
}

.Mahjong .Kakan2 :nth-child(4) {
  grid-row: 1/2;
  grid-column: 2;
  position: relative;
  top: 8px;
}

.Mahjong .Kakan3 {
  display: grid;
  align-content: end;
}

.Mahjong .Kakan3 :nth-child(1) {
  grid-row: 1/3;
  grid-column: 1;
}

.Mahjong .Kakan3 :nth-child(2) {
  grid-row: 1/3;
  grid-column: 2;
}

.Mahjong .Kakan3 :nth-child(3) {
  grid-row: 2/3;
  grid-column: 3;
}

.Mahjong .Kakan3 :nth-child(4) {
  grid-row: 1/2;
  grid-column: 3;
  position: relative;
  top: 8px;
}

.Mahjong .Kawa {
  position: absolute;
  top: 130px;
  left: 270px;
  width: 240px;
  display: flex;
  flex-wrap: wrap;
}

.Mahjong .Wait {
  border: double;
}

.Mahjong .End {
  position: absolute;
  font-size: large;
  top: 25%;
  right: 25%;
  width: 50%;
  height: 50%;
  background-color: darkgray;
  font-size: xx-large;
  text-align: center;
}

.Mahjong .End > div {
  display: flex;
}

.Mahjong .End .Name {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Mahjong .Action {
  position:relative;
  top: -5px;
  width: 800px;
  min-height: 80px;
  background-color: darkgreen;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.Mahjong .Action > div {
  margin-right: 10px;
}

.Mahjong .Agari {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 10%;
  left: 10%;
  background-color: gray;
  font-size: xx-large;
  display: grid;
  grid-template-rows: 30% 30% 40%;
  grid-template-columns: 40% 1fr;
  border: groove;
}

.Mahjong .Agari .Name {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Mahjong .AgariHai {
  border: double;
  grid-row: 1;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Mahjong .Agari .Dora {
  grid-row: 2;
  grid-column: 1;
  font-size: large;
  border: double;
}

.Mahjong .Agari .Hais {
  display: flex;
  height: 50%;
  justify-content: left;
  align-items: center;
}

.Mahjong .Agari .Yaku {
  height: 100%;
  justify-content: right;
  font-size: x-large;
  border: double;
  grid-row: 1/3;
  grid-column: 2;
}

.Mahjong .Agari .Score {
  border: double;
  grid-row: 3;
  grid-column: 1/3;
  position: relative;
  text-align: center;
}

.Mahjong .Agari .Yaku td:nth-child(2) {
  text-align: right;
}
.Mahjong .Agari .Yaku td:nth-child(1) {
  text-align: left;
}
.Mahjong .Agari .Yaku .Total td {
  text-align: right;
  vertical-align: bottom;
}

.Mahjong .Agari .Yaku .Detail {
  height: 20px;
}

.Mahjong .Agari .Score .S0 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.Mahjong .Agari .Score .S1 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.Mahjong .Agari .Score .S2 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.Mahjong .Agari .Score .S3 {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.Mahjong .Huriten {
  position: absolute;
  top: 75%;
  left: 15%;
  font-size: x-large;
  background-color: white;
}

.Mahjong .Debug {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 10%;
  left: 10%;
  background-color: gray;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.Mahjong .Draft {
  position: absolute;
  top: 75%;
  left: 16%;
  display: flex;
}

.MahjongPa .Field {
  width: 800px;
  height: auto;
  background-color: darkgreen;
  display: inline-block;
  position: relative;
}

.MahjongPa .Ba {
  width: 120px;
  height: 60px;
  position: relative;
  top: 10px;
  left: 2%;
  transform: translate(0%, 0%);
}

.MahjongPa .Yama {
  position: relative;
  top: -30px;
  left: 150px;
  width: 60px;
}

.MahjongPa .Wanpai{
  position:absolute;
  top: 20px;
  left: 250px;
}

.MahjongPa .Players{
  display:flex;
  flex-direction:column;
  position:relative;
  left: 5%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.MahjongPa .Player{
  height: auto;
  position:static;
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  margin-top: 10px;
}

.MahjongPa .Player .Status{
  position:static;
}

.MahjongPa .Yaku{
  background-color: white;
}

.MahjongPa .Spacer{
  width:100%;
}

.MahjongPa .You{
  order: 99;
  margin-top: 30px;
}

.MahjongPa .Player .Hand{
  position:static;
  margin-left: 10px;
}

.MahjongPa .AgariPa{
  position:absolute;
  top: 30px;
  left: 400px;
  width: 60px;
  background-color: white;
  font-size: x-large;
  border-radius: 10px 10px 10px 10px;
}

