.HakyokuDice .Game{
  display: flex;
}

.HakyokuDice .Players{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.HakyokuDice .Player{
  outline: black solid 1px;
  height: 120px;
  margin: 8px 0;
  display:flex;
}

.HakyokuDice .Wait{
  outline: black double 6px !important;
}

.HakyokuDice .Player .Name{
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.HakyokuDice .Players .You{
  order: 99;
  margin-top: 30px;
}

.HakyokuDice .Player .Chip img{
  width: 100px;
  margin:1px;
}

.HakyokuDice .Player .Dices{
  display:flex;
  width: 210px;
}

.HakyokuDice .Player .Dice{
  outline: 1px solid black;
  margin: 1px;
}

.HakyokuDice .Player .Dice img{
  width: 40px;
}

.HakyokuDice .Message{
  order: 100;
  height: 20px;
  margin-top: 3px;
}

.HakyokuDice .Action{
  order: 101;
  margin-top: 5px;
  height: 40px;
  /* outline: 2px black double; */
}

.HakyokuDice .Action button{
  margin:2px;
}

.HakyokuDice .Field{
  display:flex;
  flex-wrap:wrap;
  width: 600px;
}

.HakyokuDice .Field > div{
  margin: 5px;
}

.HakyokuDice .Field .Chip img{
  width: 180px;
}

.HakyokuDice .Selected{
  outline: double 2px red !important;
}

.HakyokuDice .CanSelect{
  /* outline: double 2px black; */
}