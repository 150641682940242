.Poker .Others {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
}

.Poker .Player {
  width: 130px;
  height: 180px;
  margin: 10px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: 1px #000000 solid;
}

.Poker .Player > div {
  margin-left: 2px;
}

.Poker .Dealer {
  background-color: rgb(171, 214, 212);
}

.Poker .Turn {
  outline: 4px #000000 double !important;
}

.Poker .Player .Hand {
  display: flex;
}

.Poker .Player .Card img {
  width: 60px;
  margin: 1px;
}

.Poker .Field {
  width: 400px;
  height: 160px;
  background-color: darkgreen;
  border: 1px #000000 solid;
  display: inline-block;
  position: relative;
  margin-top: 10px;
}

.Poker .Field .Community {
  position:absolute;
  top:40px;
  left: 25px;
  display:flex;
}

.Poker .Field .Pot{
  background-color: white;
  position:absolute;
  top: 13px;
  left: 150px;
  padding: 2px;
}

.Poker .Field .GameNo{
  background-color: white;
  position:absolute;
  top: 13px;
  left: 50px;
  padding: 2px;
}


.Poker .Field .Card img{
  margin:1px;
}

.Poker .Action{
  display: flex;
  height: 80px;
  margin-top: 10px;
}

.Poker .Action > div{
  margin: 2px;
}