
.HimeSyogi .Game{
  position:relative;
}

.HimeSyogi .Field2{
  position:relative;
  width:700px;
  height:726px;
  outline: 2px double black;
}

.HimeSyogi .FieldRev{
  transform:rotate(180deg);
}


.HimeSyogi .Field2 .Back{
  position:absolute;
  top:0;
  left:0;
  width: 700px;
}

.HimeSyogi .Field2 table{
  position:absolute;
  top:23px;
  left:59px;
}

.HimeSyogi .Field2 td:nth-child(2n){
  position:relative;
  top: +68px;
}

.HimeSyogi .Field2 .Hex{
  width: 100px;
  height: 100px;
  margin: 15px 6px;
}

.HimeSyogi .Field2 .Chip img{
  width: 100px;
}

.HimeSyogi .Field .Last{
  outline: 2px double white;
  outline-offset: -9px;
}


.HimeSyogi .Field3{
  position:relative;
  width:750px;
  height:791px;
  outline: 2px double black;
}

.HimeSyogi .Field3 .Back{
  position:absolute;
  top:0;
  left:0;
  width: 750px;
}

.HimeSyogi .Field3 table{
  position:absolute;
  top:0px;
  left:35px;
}

.HimeSyogi .Field3 td:nth-child(2n+1){
  position:relative;
  top: +58px;
}

.HimeSyogi .Field3 .Hex{
  width: 92px;
  height: 92px;
  margin: 8px 0px;
  /* outline: red 2px solid; */
}

.HimeSyogi .Field3 .Chip img{
  width: 100px;
}

.HimeSyogi .Field .Dir2{
  transform:rotate(120deg);
}

.HimeSyogi .Field .Dir3{
  transform:rotate(180deg);
}

.HimeSyogi .Field .Dir4{
  transform:rotate(240deg);
}

.HimeSyogi .Player{
  outline: black 1px solid;
}

.HimeSyogi .Turn{
  outline: black 3px double;
}

.HimeSyogi .You2{
  position:absolute;
  top: 480px;
  left: 720px;
}


.HimeSyogi .You3{
  position:absolute;
  top: 530px;
  left: 770px;
}

.HimeSyogi .You .Cards{
  display:flex;
}

.HimeSyogi .You .Discard{
  margin-left:30px;
}

.HimeSyogi .Other{
  width: 180px;
  height: 280px;
}

.HimeSyogi .Other21{
  position:absolute;
  top: 0px;
  left: 720px;
}

.HimeSyogi .Other31{
  position:absolute;
  top: 0px;
  left: 770px;
}

.HimeSyogi .Other32{
  position:absolute;
  top: 0px;
  left: 1100px;
}


.HimeSyogi .Player .Name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.HimeSyogi .Hand{
  display:flex;
}
.HimeSyogi .Card{
  margin: 0px 2px;
}
.HimeSyogi .Card img{
  width: 150px;
}

.HimeSyogi .Player .Chips{
  display:flex;
}

.HimeSyogi .Player .Chips img{
  width: 120px;
  margin: 2px;
}

.HimeSyogi .Selected{
  position:relative;
  top:-10px;
}

.HimeSyogi .CanSelect{
  outline: black 5px double;
}

.HimeSyogi .Message2{
  position:absolute;
  top: 380px;
  left: 720px;   
}

.HimeSyogi .Action2{
  position:absolute;
  top: 410px;
  left: 720px;   
}


.HimeSyogi .Message3{
  position:absolute;
  top: 430px;
  left: 770px;
}

.HimeSyogi .Action3{
  position:absolute;
  top: 460px;
  left: 770px;   
}

